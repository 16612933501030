import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PublicDonationsList, PublicDonation, PublicDonationConfirm, PublicDonationError, PublicDonationPrivacy, PublicDonationConditions, PublicDonationFiscal  } from './components/PublicDonations';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Importa tu archivo de configuración de i18n
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const NotFound = () => {    
  return (<span>La pàgina no s'ha trobat</span>)
};

function App() {
    
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
    <ToastContainer />
    <Router>
      <Routes>
        <Route path="/" element={<PublicDonationsList />} />
        <Route path="/entitats/:associations_id/fiscalitat" element={<PublicDonationFiscal />} />  
        <Route path="/fiscalitat" element={<PublicDonationFiscal />} />  
        <Route path="/entitats/:associations_id" element={<PublicDonationsList />} />
        <Route path="/entitats/:associations_id/politica-de-privadesa" element={<PublicDonationPrivacy />} />  
        <Route path="/entitats/:associations_id/condicions-dus" element={<PublicDonationConditions />} />                  
        <Route path="/:id" element={<PublicDonation />} />  
        <Route path="/transfer/confirm" element={<PublicDonationConfirm />} />
        <Route path="/tpv/confirm" element={<PublicDonationConfirm />} />
        <Route path="/tpv/error" element={<PublicDonationError />} /> 
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>
    </Router>
    </I18nextProvider>
    
    );
        
}

export default App;
