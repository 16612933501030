import React, { useState } from 'react';

import { useEffect  } from 'react';

import { apiUrl, apiOrigin, apiDefaultLanguage } from './../common/constants';


export const ImageViewer = ({...props}) => {

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
        
      let headers = new Headers({ Accept: 'application/json' });
      headers.set('Access-Control-Allow-Origin', apiOrigin );
      headers.set('Authorization', localStorage.getItem('auth'));
      headers.set('Accept-Language', apiDefaultLanguage);
      headers.set('Role', localStorage.getItem('active_permission'));

      let urlApi1 = apiUrl;
      urlApi1 += props.is_public ? '/docs/download/public/' : '/docs/download/';
      urlApi1 += props.docs_id;
                
      const response = await fetch(urlApi1 , { 
        headers: headers
      });
        
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setImageUrl(url);        
    };

    fetchImage();
  }, [props.docs_id]);
  
  return ( 
    imageUrl ?
      <>
        { props.is_div ? 
          <div className={props.className} style={{backgroundImage: 'url("' + imageUrl + '")'}}/>
          :
          props.link ? 
            <a href={imageUrl} download={props.link_name} target="_blank"><img src={imageUrl} className={props.className} title={props.title} alt={props.title} /></a> 
            : 
            <img src={imageUrl} className={props.className} title={props.title} alt={props.title} /> 
        }            
      </>
      : 
            
      <div className={props.className} style={{backgroundImage: null }} ><span></span></div>
  );
};
