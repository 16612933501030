export const apiUrl = window.location.hostname === 'localhost' ? 'http://localhost:81' :
  window.location.hostname === 'testentitats.sifac.cat' ? 'https://testentitats.sifac.cat:8443' :
    window.location.hostname === 'entitats.sifac.cat' ? 'https://entitats.sifac.cat:8443' :
      window.location.hostname === 'admin.gestioentitats.cat' ? 'https://api.gestioentitats.cat:8443' :
        window.location.hostname === 'donatius.sifac.cat' ? 'https://entitats.sifac.cat:8443' :
        'https://api.gestioentitats.cat:8443';
        
// CONSTANTS
export const apiOrigin = 'http://localhost:3000';
export const apiDefaultLanguage = 'ca';

