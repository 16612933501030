import React from 'react';
import Pagination from '@mui/material/Pagination';


const Paginador = ({ page, perPage, total, onPageChange }) => {
    
  const totalPages = Math.ceil(total / perPage);
  
  const handlePageChangeNew = (event, value) => {
    onPageChange(value);
  };
  
  //console.log(page + ' - ' + perPage + ' - ' + total );

  return (    
    <div className="pagination-container">
      <Pagination className="my-pagination" count={totalPages} page={page} hidePrevButton={page === 1} hideNextButton={page === totalPages || totalPages === 0} onChange={handlePageChangeNew} size="large" />
    </div>
  );
};

export default Paginador;
