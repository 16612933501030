import { format } from 'date-fns';
import { ca } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import {
  DateField, FunctionField
} from 'react-admin';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export const LinearProgressWithLabel = props => {      
  let linearProgressValue = props.value > 100 ? 100 : props.value;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={linearProgressValue} className="donations_linear_progress" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const RemainingTime = ({nSeconds, remainText, agoText}) => {  
    
    const {t} = useTranslation();  
        
    let days = Math.floor(nSeconds / ( 60 * 60 * 24));
    let hours = Math.floor(nSeconds / (60*60));
    let minutes = Math.floor(nSeconds / (60));
               
    let retValue = '';
    let remain = t('remain');
    
    if (nSeconds > 2*60*60*24)
        retValue = days + ' ' + t('days');
    else if (nSeconds > 60*60*24)
    {
        retValue = days + ' ' + t('day');
        remain = t('remain_1');
    }
    else if (nSeconds > 2*60*60)
        retValue = hours + ' ' + t('hours');
    else if (nSeconds > 60*60)
    {
        retValue = hours + ' ' + t('hour');
        remain = t('remain_1');
    }
    else if (nSeconds > 2*60)
        retValue = minutes + ' ' + t('minutes');
    else if (nSeconds > 60)
    {
        retValue = minutes + ' ' + t('minute');
        remain = t('remain_1');
    }
    else if (nSeconds > 1)
        retValue = nSeconds + ' ' + t('seconds');
    else if (nSeconds > 0)
    {
        retValue = nSeconds + ' ' + t('second');
        remain = t('remain_1');
    }
    
    if (remainText && retValue.length )
    {        
        return (<><span className="fs-14 grey8">{remain}</span><br/><span className="fs-20 bold">{retValue}</span></>);
    }
    if (agoText && retValue.length )
    {        
        return (<><span className="fs-14 grey8">{t('ago')} {retValue}</span></>);
    }
    
    return (<span>{retValue}</span>);
    
}

export const formatFloat = (number) => {
    return number.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
}
