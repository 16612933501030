// in src/Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form"


import {
    WhatsappShareButton,
    TwitterShareButton,
    FacebookShareButton,
    EmailShareButton,
    TelegramShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon  
} from "react-share";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

import "./../common/styles.css"


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Carousel from 'react-material-ui-carousel';

import TextField from '@mui/material/TextField';

import Paginador from './Paginador';

import IconButton from '@mui/material/IconButton';

import 'bootstrap/dist/css/bootstrap.min.css';

//import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Button from '@mui/material/Button';

import { parse } from 'query-string';


import CancelIcon from '@mui/icons-material/Cancel';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import EuroIcon from '@mui/icons-material/Euro';

import { useTranslation } from 'react-i18next';

//import { useTranslate  } from '../common/language';
import { apiUrl  } from '../common/constants';

import MyLoading from '../components/MyLoading';
import YoutubeVideo from '../components/YoutubeVideo';


import {  ImageViewer } from '../resources/docs';
import {  validarNIF,validarCIF,validarDNI,validarDNIUEGeneric } from '../components/Validator';

import { dateEventToCatalan, timeEventToCatalan, LinearProgressWithLabel, RemainingTime, formatFloat } from '../common/utils';


import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';





//import { Form } from 'react-final-form';
import { useNavigate, useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Chip from '@mui/material/Chip';

export const PublicDonationsList = (props) => {
  
  const {t} = useTranslation();  
    
  const navigate = useNavigate();  
  const location = useLocation();
    
  const searchParams = new URLSearchParams(location.search);    
    
  const { hash, pathname, search } = location;
  
  const { associations_id: associations_id_string } = parse(search);    
   
  
  const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')) : 1);
  const [q, setQ] = useState(searchParams.get('q') ? searchParams.get('q') : '');      
  
  const [info, setInfo] = useState();
  const [donationsProjects, setDonationsProjects] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);  
            
  const { associations_id } = useParams();
          
  const updateUrl = (field,value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(field, value);
    if (field !== 'page') searchParams.set('page', 1);
    const newSearch = searchParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch
    });
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    updateUrl('page',newPage);
    window.scrollTo(0, 0);        
  };
    
  const handleSubmit = (values) => {
    
  };
        
  var today = new Date().toISOString().slice(0, 10);
    
  const perPage = 9;
    
  useEffect(() => {
    
    let url  = '';
    if (associations_id)    
        url = apiUrl + '/donations_projects/public?associations_id=' + associations_id + '&page=' + (page-1) + '&size=' + perPage + '&search=' + q;
    else
        url = apiUrl + '/donations_projects/public/fac?page=' + (page-1) + '&size=' + perPage + '&search=' + q;
    
    const config = {
        headers: {
          'Content-Type': 'application/json', 
          'Accept-Language': 'ca' 
        }
      };
      
    axios.get(url, config).then((response) => {
      setDonationsProjects(response.data.content);
      setInfo(response.data);
      setLoading(false); 
    })
    .catch((error) => {
        // Manejar el error aquí
        console.error('Error en la solicitud:', error);
        setLoading(false);
        setError(true); // Establecer el estado de error en true        
    });
                                                     
  }, [ page, q ]);
        
  if (loading) return <><br/><br/><br/><br/><MyLoading /></>;
  if (error) return <NotFoundPage />;
        
  if (!donationsProjects ) return null;
                  
  return (
    <div className="donations_projects">
    <HeaderFAC associations_id={associations_id} />
      
    <Box sx={{ width: '95%', bgcolor: 'background.paper', paddingLeft: '1%', paddingRight: '1%' }} className="add_member_box pt-0 mt-10" >
      
      <Grid container spacing={1}>
        <Grid item xs={12}>  
            
        </Grid>
      </Grid>   
                  
      <Grid container spacing={1} className="public-events-filter">
        <Grid item xs="3" md="7" lg="9">  
        </Grid>
        <Grid item xs="9" md="5" lg="3">  
          <TextField value={q} source="q" className="public-events-filter-mt" fullWidth id="standard-basic" label={t('search')} variant="standard" placeholder="" onChange={(event) => { setQ(event.target.value); setPage(1); updateUrl('q',event.target.value); }}/>  
        </Grid>                         
      </Grid> 
          
      <br/><br />  
            
      <Grid container spacing={2}>
        {donationsProjects.map((item) => {  
               
          return (
            <Grid key={item.id} item xs="12" sm="6" lg="4" textAlign="left" className="classrooms_list_item_container">  
              <Card className="donations_cards">                            
                            
                { item.images && item.images.length > 0 ? 
                  <ImageViewer docs_id={item.images[0]} is_public={true} title={item.title} is_div={true} className="public-events-img" />
                  : 
                  null
                }                
                <ProjectResume list={true} item={item} associations_id={associations_id} is_external_project={associations_id!=item.associations_id} />                
              </Card>
            </Grid>   
          );})}
      </Grid>
                        
      <Paginador page={page} perPage={perPage} onPageChange={onPageChange} total={info.totalElements} />
                     
      <br /><br />
      
    </Box>
        
    <FooterFAC associations_id={associations_id} />
        
    </div>
  );
};

export const ProjectResume = props => {
    const {t} = useTranslation(); 
    
    let item = props.item;
    
    let label = t('live');
    let contribute_button = true;
    if (item.target_by_date && item.remaining_seconds == -1) { label = t('finished'); contribute_button = false; }
    if (item.target_by_date && item.remaining_seconds == -2) { label = t('early'); contribute_button = false; }
    
    return (
        <>
        <CardContent>
                  
        <Chip label={label} variant="outlined" className="bg-orange bc-orange upper mb-10 color-white text-align-left bold fs-16" />                                

        { props.list ?
        <Box display="flex" alignItems="center" className="mb-10">                    
          <Typography color="" align="justify" className="fs-12 grey8" >
          {t('for')}: <b><a href={'/entitats/' + item.associations_id} className="classrooms_list_item_link">{ item.associations_name }</a></b>
          {item.city ? ' ('+item.city+')' : null }
          </Typography>
        </Box> 
        : null }

        { props.list ?
        <a href={'/' + item.friendly_url} className="classrooms_list_item_link">
          <Typography gutterBottom variant="h5" component="div" align="left" className="bold">
            {item.name }
          </Typography>
        </a>
        : null }
        
        {props.list ? 
        <Typography variant="body2" color="" align="justify" className="public-events-description grey9">
          {item.description_short ? item.description_short.replace(/<[^>]*>/g, '') : null}
        </Typography> 
        : null }

        { !item.target_by_date && item.public_sum_donations ? 
        <Grid container spacing={2} className="mt-0">
          <Grid item xs={6} textAlign="center" className="ff-roboto lh-30">  
            <span className="fs-14 grey8">{t('count_donations')}</span><br/><span className="fs-20 bold">{formatFloat(item.count_donations)}</span>
          </Grid>
          <Grid item xs={6} textAlign="center" className="ff-roboto lh-30">  
            <span className="fs-14 grey8">{t('win')}</span><br/><span className="fs-20 bold">{formatFloat(item.sum_donations) + ' €'}</span>
          </Grid>
        </Grid> : null }                  

        { item.target_by_date ?
        <Grid container spacing={2} className="mt-0">
          <Grid item xs={12} textAlign="left" className="">  
              <Grid container spacing={2} className="mt-0">
                  {item.remaining_seconds > 0 ?
                  <Grid item xs={6} textAlign="center" className="ff-roboto lh-30">  
                      <RemainingTime nSeconds={item.remaining_seconds} remainText={true} />
                  </Grid>
                  : null }
                  <Grid item xs={item.remaining_seconds > 0 ? 6 : 4} textAlign="center" className="ff-roboto lh-30">  
                  <span className="fs-14 grey8">{t('count_donations')}</span><br/><span className="fs-20 bold">{formatFloat(item.count_donations)}</span>
                  </Grid>
                  <Grid item xs={item.remaining_seconds > 0 ? 6 : 4} textAlign="center" className="ff-roboto lh-30">  
                      <span className="fs-14 grey8">{t('win')}</span><br/><span className="fs-20 bold">{formatFloat(item.sum_donations) + ' €'}</span>                      
                  </Grid>      
                  <Grid item xs={item.remaining_seconds > 0 ? 6 : 4} textAlign="center" className="ff-roboto lh-30">  
                    <span className="fs-14 grey8">{t('goal')}</span><br/><span className="fs-20 bold">{formatFloat(item.amount) + ' €'}</span>                      
                  </Grid> 
                  {item.remaining_seconds > 0 ?
                  <Grid item xs={0} textAlign="center" >                             
                  </Grid>
                  : null }
              </Grid>                        
          </Grid>
          <Grid item xs={12} textAlign="left" className="">  
              <LinearProgressWithLabel value={item.sum_donations_percent} />              
          </Grid>
        </Grid>

        : null }

        </CardContent>  
        <CardActions align="right">
            <Box flexGrow={1} />
            {props.list ? <a href={'/' + item.friendly_url} className="classrooms_list_item_link"><Button size="small" className="donations-buttons-1">{t('more_info')} </Button></a> : null }
            {contribute_button ? 
              <ContributeButton id={item.id} name={item.name} comments_actived={item.comments_actived} retryPayment={props.retryPayment} payment_systems_fac={item.payment_systems_fac} payment_transfer={item.payment_transfer} payment_card={item.payment_card} list={props.list} friendly_url={item.friendly_url} associations_id={item.associations_id} is_external_project={props.is_external_project} />
            : null }
          </CardActions>
        </>
    )
}

export const FooterFAC = props => {

    const [info, setInfo] = useState();
    
    useEffect(() => {
        let url  = '';
        if (props.associations_id)    
            url = apiUrl + '/donations_projects/public/html?associations_id=' + props.associations_id;
        else
            url = apiUrl + '/donations_projects/public/html/fac';

        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };

        axios.get(url, config).then((response) => {      
          setInfo(response.data);      
        })
        .catch((error) => {      
        });
    },[] );
    
    if (!info) return null;
    
    /*
     * <Grid container spacing={2} className="donations-footer">
        <Grid item xs="12" md="12" lg="1">  
        </Grid>
        <Grid item xs="12" md="12" lg="4" textAlign="center" className="footer-left">  
            Entitat d'utilitat pública segons la resolució JUS/675/2013
        </Grid>
        <Grid item xs="12" md="12" lg="4" textAlign="left" className="footer-center">  
          <a href="https://www.ateneus.cat/contacte/"  target="_blank">Contacta amb nosaltres</a>
          <a href="https://www.ateneus.cat/som-transparents/"  target="_blank">Som transparents</a>
          <a href="https://www.ateneus.cat/avis-legal/" target="_blank">Avís legal</a>
        </Grid>
        <Grid item xs="12" md="12" lg="2" textAlign="center" className="footer-right">  
          <a href="tel:93 268 81 30">93 268 81 30</a>
        </Grid>
        <Grid item xs="12" md="12" lg="1">  
        </Grid>
    </Grid> 
     */
    return (
        <div dangerouslySetInnerHTML={{ __html: info.donations_own_footer }} />
    )
}

export const HeaderFAC = props => {
    
    const [info, setInfo] = useState();
    
    useEffect(() => {
        let url  = '';
        if (props.associations_id)    
            url = apiUrl + '/donations_projects/public/html?associations_id=' + props.associations_id;
        else
            url = apiUrl + '/donations_projects/public/html/fac';

        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };

        axios.get(url, config).then((response) => {      
          setInfo(response.data);      
        })
        .catch((error) => {      
        });
    },[] );
    
    if (!info) return null;
    
    /* 
     * <Grid container spacing={2} className="donations-header">
        <Grid item xs="12" md="5" lg="4" textAlign="left" className="header-left">  
            <a href="https://www.ateneus.cat"><img src="https://www.ateneus.cat/wp-content/uploads/2017/04/logo-federacio-ateneus.png.webp" /></a>
        </Grid>   
        <Grid item xs="12" md="4" lg="4" textAlign="center" className="header-right">  
            <h1>DONATIUS A PROJECTES</h1>
        </Grid> 
        <Grid item xs="0" md="3" lg="4" textAlign="center" className="header-right"> 
        </Grid> 
    </Grid> 
     */
    
    let url_fiscalitat = props.associations_id ? '/entitats/'+props.associations_id+'/fiscalitat' : '/fiscalitat'
    
    return (
    <>     
        
    <div dangerouslySetInnerHTML={{ __html: info.donations_own_header }} />
    
    <Box sx={{ width: '95%', bgcolor: '#FFDCB3', paddingLeft: '2%', paddingRight: '2%',textAlign: 'center', borderRadius: '10px', padding : '10px'}} className="add_member_box mt-10" >
        <span>Tant les persones físiques com jurídiques poden desgravar-se i beneficiar-se fiscalment de les aportacions que facin a les Entitats d'Utilitat Pública. <a href={url_fiscalitat} target="_blank">Saber-ne més</a></span> 
    </Box>
    { props.associations_id != 234 ? 
    <Box sx={{ width: '95%', bgcolor: '#FFDCB3', paddingLeft: '2%', paddingRight: '2%',textAlign: 'center', borderRadius: '10px', padding : '10px'}} className="add_member_box mt-10" >
        <span>Si sou una entitat federada i voleu recollir donatius per a projectes de la vostra entitat, a través de la FAC, contacteu amb <a href="mailto:ateneus@ateneus.cat">ateneus@ateneus.cat</a></span> 
    </Box> : null }
    
    </>
    )
}

export const NotFoundPage = props => {
    
    const {t} = useTranslation();  
    
    return (
    <div className="donations_projects">
    <Box sx={{ width: '95%', bgcolor: 'background.paper', paddingLeft: '1%', paddingRight: '1%', paddingTop: '10%', paddingBottom: '10%', textAlign: 'center' }} className="add_member_box" >
    {t('not_found')}
    </Box>      
    </div>
    )
}

export const ContributeButton = props => {

    const {t} = useTranslation();  
    
    const [showDialog, setShowDialog] = useState(false);
    const [retryPaymentInfo, setRetryPaymentInfo] = useState(false);
    const [error, setError] = useState(false);
    
    useEffect(() => {
        
        if (props.retryPayment) {
            setShowDialog(true);
            
            const config = {
                headers: {
                  'Content-Type': 'application/json', 
                  'Accept-Language': 'ca' 
                }
              };
          
            axios.post(apiUrl + '/donations_users/public/notify/show?sha1hash='+props.retryPayment, { } , config )
                .then(function (response) {  
                    if (response.data.status != 1) {
                        setRetryPaymentInfo(response.data);
                    }
                    else
                    {
                        setError(true);
                    }
                })
                .catch(function (error) {
                    setError(true);
              });
        }
        
    }, []);
  
    const handleClick = () => {
        setShowDialog(true);
      };

      const handleCloseClick = () => {
        setShowDialog(false);
      };
    
    const handleSubmitForm = async values => {
                
        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };
        
        axios.post(apiUrl + '/donations_users/public', values, config )
          .then(function (response) {
                                               
                if (response.data.payment_method == 0)
                {
                    window.location.href='/transfer/confirm?sha1hash=' + response.data.SHA1HASH;
                }
                else if (response.data.payment_method == 1 && response.data.plattform == 1)
                {
                    // ADDONS                                        
                    const formData = {
                        
                        TIMESTAMP : response.data.TIMESTAMP,
                        MERCHANT_ID : response.data.MERCHANT_ID,
                        ACCOUNT : response.data.ACCOUNT,
                        ORDER_ID : response.data.ORDER_ID,
                        CURRENCY : response.data.CURRENCY,
                        AMOUNT : response.data.AMOUNT,
                        SHA1HASH : response.data.SHA1HASH,
                        AUTO_SETTLE_FLAG : response.data.AUTO_SETTLE_FLAG,
                        HPP_BILLING_CITY: response.data.HPP_BILLING_CITY,
                        HPP_BILLING_COUNTRY: response.data.HPP_BILLING_COUNTRY,
                        HPP_BILLING_STREET1: response.data.HPP_BILLING_STREET1,
                        HPP_BILLING_POSTALCODE: response.data.HPP_BILLING_POSTALCODE,
                        HPP_CUSTOMER_EMAIL: response.data.HPP_CUSTOMER_EMAIL,
                        HPP_LANG: response.data.HPP_LANG,
                        HPP_VERSION: response.data.HPP_VERSION,
                        MERCHANT_RESPONSE_URL: response.data.MERCHANT_RESPONSE_URL,
                        CARD_PAYMENT_BUTTON: response.data.CARD_PAYMENT_BUTTON,
                        SUPPLEMENTARY_DATA: response.data.SUPPLEMENTARY_DATA,
                        
                    };

                    // Creem un formulari
                    const form1 = document.createElement('form');
                    form1.setAttribute('name', 'form');
                    form1.setAttribute('action', response.data.url );
                    form1.setAttribute('method', 'POST');

                      // Afegim els camps ocults al formulari
                    Object.keys(formData).forEach(key => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'hidden');
                        input.setAttribute('name', key);
                        input.setAttribute('value', formData[key]);
                        form1.appendChild(input);
                    });

                    // Afegim el formulari al cos del document i l'enviem
                    document.body.appendChild(form1);
                    form1.submit();
                }
                else if (response.data.payment_method == 1 && response.data.plattform == 0) 
                {
                    // REDSYS
                    const formData = {
                        Ds_SignatureVersion: response.data.ds_signature_version,
                        Ds_MerchantParameters: response.data.ds_merchant_parameters,
                        Ds_Signature: response.data.ds_signature
                    };

                    // Creem un formulari
                    const form1 = document.createElement('form');
                    form1.setAttribute('name', 'form');
                    form1.setAttribute('action', response.data.url );
                    form1.setAttribute('method', 'POST');

                      // Afegim els camps ocults al formulari
                    Object.keys(formData).forEach(key => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'hidden');
                        input.setAttribute('name', key);
                        input.setAttribute('value', formData[key]);
                        form1.appendChild(input);
                    });

                    // Afegim el formulari al cos del document i l'enviem
                    document.body.appendChild(form1);
                    form1.submit();
                }
            
          })
          .catch(function (error) {
          
            let message = "S'ha produit un error";
            if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
          
            toastError(message);
           
          });

      };
  
    if (props.retryPayment && !error && !retryPaymentInfo) return '';
        
    return (
            <>
            { !props.payment_systems_fac && props.list && props.is_external_project ?
            <a href={'/' + props.friendly_url } className="classrooms_list_item_link">
                <Button variant="contained" size="medium" className="donations-buttons-2"><b>{t('contribute')}</b></Button>
            </a>
            :
            <a onClick={()=> handleClick(props.id) } className="classrooms_list_item_link">
                <Button variant="contained" size="medium" className="donations-buttons-2"><b>{t('contribute')}</b></Button>
            </a>
            }            
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={t('contribute')}
              >
                <DialogTitle>{t('contribute_to')} {props.name}</DialogTitle>
                                
                <MyForm associations_id={props.associations_id} donations_projects_id={props.id} handleCloseClick={handleCloseClick} handleSubmitForm={handleSubmitForm} comments_actived={props.comments_actived} payment_systems_fac={props.payment_systems_fac} payment_card={props.payment_card} payment_transfer={props.payment_transfer} retryPaymentInfo={retryPaymentInfo} />
                    
              </Dialog>
            </>
            );
}

const MyForm = ({associations_id, donations_projects_id, comments_actived, handleCloseClick, handleSubmitForm, retryPaymentInfo, payment_systems_fac, payment_transfer, payment_card }) => {

  const formRef = useRef(null);
  const {t} = useTranslation();  
  
  let default_payment_method = payment_systems_fac ? 1 : payment_card ? 1 : payment_transfer ? 0 : 1;
  
  const [formData, setFormData] = useState( retryPaymentInfo ? { ...retryPaymentInfo, email_repeat: retryPaymentInfo.email, lopd: true, conditions: true }
        :
        {
            payment_method: default_payment_method,
            user_type: 0,
            donations_projects_id: donations_projects_id,
            want_certificate: true,
            lopd: false,
            conditions: false
        }
  );
    
  const handleChange = (e) => {
    
    setFormData({
     ...formData,
      [e.target.name]: e.target.name == 'want_certificate' || e.target.name == 'lopd' || e.target.name == 'conditions' ? e.target.checked : e.target.value,
    });
    
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Aquí puedes manejar el envío del formulario
    const formData = new FormData(formRef.current);
        
    // VALIDADOR
    var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    const nif = formData.get('nif');
    const want_certificate = formData.get('want_certificate');
    const email = formData.get('email');
    const emailRepeat = formData.get('email_repeat');

    if (want_certificate && !validarDNIUEGeneric(nif) )
    {
        toastError('El DNI/NIF és incorrecte');
        return;
    } 
    if (!email.match(EMAIL_REGEX))
    {
        toastError('El correu electrònic no és vàlid');
        return;
    }
    if (email !== emailRepeat) {
      toastError('Els correus electrònics no coincideixen');
      return;
    }
    
    
    handleSubmitForm(formData);
  };
  
  return (
     <>
     <form onSubmit={handleSubmit} className="donations-form" ref={formRef}>
        <input type="hidden" name="donations_projects_id" value={donations_projects_id} />
        <DialogContent>    
        <Grid container spacing={2}>
          
          <Grid item xs={12} md={8} lg={8}>
            <RadioGroup
              aria-label={t('payment_method')}
              value={formData.payment_method}      
              label={t('payment_method')}
              variant="standard"
              className="donations-form-radio"
              name="payment_method"
              onChange={handleChange}
            >
              { payment_systems_fac || payment_card ?
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t('pay_card')}
                variant="standard"
              /> : null }
              { payment_systems_fac || payment_transfer ?
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t('pay_transfer')}
                variant="standard"
              /> : null }
            </RadioGroup>
          </Grid>
          
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label={t('amount')}
              name="amount"
              value={formData.amount}
              required
              variant="standard"
              type="number"
              step={0.01}
              onChange={handleChange}
            />
          </Grid>          
          
          <Grid item xs={12} md={8} lg={8}>
            <RadioGroup
              aria-label={t('user_type')}               
              label={t('user_type')}
              variant="standard"
              className="donations-form-radio"
              name="user_type"
              value={formData.user_type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t('user_type_0')}
                variant="standard"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t('user_type_1')}
                variant="standard"
              />
            </RadioGroup>
          </Grid>
          
          <Grid item xs={12} md={4} lg={4}></Grid>
          
          <Grid item xs={12} md={formData.user_type == 1 ? 12 : 4} lg={formData.user_type == 1 ? 12 : 4} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('first_name')}
              name="first_name"
              value={formData.first_name}              
              variant="standard"   
              required
            />
          </Grid>
          
          {formData.user_type == 0 ?
          <Grid item xs={12} md={4} lg={4} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('last_name_1')}
              name="last_name_1"
              value={formData.last_name_1}
              required
              variant="standard"    
            />
          </Grid> : null }
          
          {formData.user_type == 0 ?
          <Grid item xs={12} md={4} lg={4} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('last_name_2')}
              name="last_name_2"
              value={formData.last_name_2}
              required
              variant="standard"     
            />
          </Grid> : null }
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('email')}
              name="email"
              value={formData.email}
              required
              variant="standard"   
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('email_repeat')}
              name="email_repeat"
              value={formData.email_repeat}
              required
              variant="standard"   
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox defaultChecked={formData.want_certificate} value={formData.want_certificate} onChange={handleChange} name="want_certificate"  />} label={t('want_certificate')} onChange={handleChange} />
          </Grid>
          { formData.want_certificate ? <>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('nif')}
              name="nif"
              value={formData.nif}
              required
              variant="standard"    
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('phone')}
              name="phone"
              value={formData.phone}
              variant="standard"    
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('address')}
              name="address"
              value={formData.address}
              required
              variant="standard"    
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('zip')}
              name="zip"
              value={formData.zip}
              required
              variant="standard"      
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('city')}
              name="city"
              value={formData.city}
              required
              variant="standard"     
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="donations-form-pt-0 ">
            <TextField
              fullWidth
              label={t('country')}
              name="country"
              value={formData.country}
              required
              variant="standard"    
            />
          </Grid>
          </> : null }
          
          { comments_actived ? 
          <Grid item xs={12} className="donations-form-pt-10 ">
            <TextField
                id="comment"
                name="comment"
                label={t('comment')}
                multiline
                rows={2}
                defaultValue=""
                variant="filled"
                fullWidth
                inputProps={{
                    maxLength: 255
                }}
              />
          </Grid> : null }
          
          <Grid item xs={12}>
          <FormControlLabel control={<Checkbox defaultChecked={formData.lopd} value={formData.lopd} onChange={handleChange} name="lopd" required />} label={<span style={{ fontSize: '12px' }}>{t('lopd')} (<a href={'/entitats/'+associations_id+'/politica-de-privadesa'} target="_blank">Veure la política de privadesa</a>)</span>} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox defaultChecked={formData.conditions} value={formData.conditions} onChange={handleChange} name="conditions" required />} label={<span style={{ fontSize: '12px' }}>{t('conditions')} (<a href={'/entitats/'+associations_id+'/condicions-dus'} target="_blank">Veure els termes i condicions dels donatius</a>)</span>} onChange={handleChange} />
          </Grid>
        </Grid>
        </DialogContent>  
      
        <DialogActions>
            <Button startIcon={<CancelIcon />} size="medium" onClick={handleCloseClick} >{t('cancel')}</Button>            
            <Button name="payment_card" type="submit" startIcon={formData.payment_method == 0 ? <EuroIcon/> : <CreditCardIcon/>} size="medium" variant="contained" disabled={!formData.lopd || !formData.conditions} >{t('pay')}</Button>
        </DialogActions>            
    </form>    
        
    </>
    
  );
};

export const PublicDonation = props => {
    
  const {t} = useTranslation();  
  const { id } = useParams();
  
  const navigate = useNavigate();
    
  const [info, setInfo] = useState();
  const [donationsProjects, setDonationsProjects] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  
  const [formSent, setFormSent] = useState();
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
    
  let hash_preview = params.get('hash_preview');
 
  let retryPayment = params.get('retry_payment');
  
  let url = ''; 
  if (hash_preview)
      url = apiUrl + '/donations_projects/public/str/preview/' + id + '/' + hash_preview;    
  else
      url = apiUrl + '/donations_projects/public/str/' + id;
        
  const backButtonClick = (url) => {             
    navigate(url);        
  };
    
  useEffect(() => {
            
    const config = {
        headers: {
          'Content-Type': 'application/json', 
          'Accept-Language': 'ca' 
        }
      };

    axios.get(url, config)
    .then((response) => {
        setDonationsProjects(response.data);
        setLoading(false);
        setError(false);    
    })
    .catch((error) => {
        // Manejar el error aquí
        console.error('Error en la solicitud:', error);
        setLoading(false);
        setError(true); // Establecer el estado de error en true        
    });
        
  }, []);
                  
  if (loading) return <><br/><br/><br/><br/><MyLoading /></>;
  if (error) return <NotFoundPage />;
  if (!donationsProjects ) return null;
  
  /*
  <div className="back_button_container cursor" onClick={() => backButtonClick(donationsProjects.payment_systems_fac ? '/' : '/entitats/' + donationsProjects.associations_id)}>
        <IconButton  aria-label={t('go_back')} className="public-events-back-button mt-20" >
          <ArrowBackIcon />
        </IconButton>
        <div className="title">{t('view_all_projects')}</div>
  </div>
  */
  
  return (
            
    <div className="donations_projects">
          
      <HeaderFAC associations_id={donationsProjects.associations_id} />
      
      { hash_preview ? 
      <Box sx={{ width: '95%', bgcolor: '#FFD3D3', paddingLeft: '2%', paddingRight: '2%',textAlign: 'center', borderRadius: '10px', padding : '10px'}} className="add_member_box mt-10" >
        <span>{t('preview')}</span> 
      </Box>
      : null }
      
      <Box sx={{ width: '90%', bgcolor: 'background.paper', paddingLeft: '1%', paddingRight: '1%' }} className="add_member_box pt-0 mt-10" >
      
      <Grid container spacing={2} className="mt-0 text-center">
        <Grid item xs={12}>
            <h2>{donationsProjects.name}</h2>
            <Typography color="" align="center" className="grey8">
            {t('for')}: <b><a href={'/entitats/' + donationsProjects.associations_id} className="classrooms_list_item_link">{ donationsProjects.associations_name }</a></b>
            {donationsProjects.city ? ' ('+donationsProjects.city+')' : null }
            </Typography>
        </Grid>
      </Grid>
        
      <Grid container spacing={2} className="mt-0 pd-5">
        
        
        <Grid item xs={12} md={7} textAlign="left" className="pd-5">  
            
            <YoutubeVideo url={donationsProjects.video} />
            
            { donationsProjects.images && donationsProjects.images.length > 1 ? 
              <Carousel className="events_carrousel_photos" fullHeightHover={true} navButtonsAlwaysVisible={true} >
                { donationsProjects.images.map( (item, i) => <div className="donations_carrousel_item_container"><ImageViewer docs_id={item} className="donations_carrousel_photos" is_public={true} link={false} title={donationsProjects.title} /></div> ) }
              </Carousel>   
              : null }
                
            { donationsProjects.images && donationsProjects.images.length === 1 ? 
              <div className="events_carrousel_photos">
                <ImageViewer docs_id={donationsProjects.images[0]} className="donations_carrousel_photos" is_public={true} link={false} />
              </div>
              : null }
            
            <br /><br />
            <Typography variant="body2" color="" align="justify" className="">
            <div className="" spacing={2} dangerouslySetInnerHTML={{ __html: donationsProjects.description }} />
            </Typography>
                
        </Grid>
            
        <Grid item xs={12} md={5} textAlign="left" className="">  
        
            <Card className="donations_cards mb-20">
                <ProjectResume list={false} item={donationsProjects} retryPayment={retryPayment} />
            </Card>
            
            <Card className="grey8 fs-14 mb-20">
                <CardContent>                        
                    <Grid container spacing={0} >
                        <Grid item xs={4} className="donations_association_image">
                            { donationsProjects.associations_image ? 
                            <ImageViewer docs_id={donationsProjects.associations_image} className="donations_carrousel_photos" is_public={true} link={false} />
                            : null }
                        </Grid>
                        <Grid item xs={8}>
                            <b>{donationsProjects.associations_name }</b>
                            {donationsProjects.city ? <><br />{donationsProjects.city }</> : null }
                            {donationsProjects.email ? <><br />{donationsProjects.email }</> : null }
                            {donationsProjects.phone ? <><br />{donationsProjects.phone }</> : null }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
            <Card className="donations_cards grey8 fs-14 mb-20">
                <CardContent>                        
                    <Grid container spacing={0} >
                        <Grid item xs={12} className="text-center"> 
                            <TwitterShareButton title={donationsProjects.name} url={window.location.href} className="mr-10 ml-10">
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <FacebookShareButton title={donationsProjects.name} url={window.location.href} className="mr-10">
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <WhatsappShareButton title={donationsProjects.name} url={window.location.href} className="mr-10">
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                            <TelegramShareButton title={donationsProjects.name} url={window.location.href} className="mr-10">
                                <TelegramIcon size={32} round={true} />
                            </TelegramShareButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
            
            {donationsProjects.comments.length ? 
            <>
            <br /><br/><span className="grey8 bold">{t('comments')}</span><br /><br />
            {donationsProjects.comments.map((comment) => {  
                return (
                    <Card className="donations_cards_comments mb-20 grey8 fs-14">
                    <CardContent>
                    <span className="bold mb-20">{comment.name}</span>&nbsp;&nbsp;<RemainingTime agoText={true} nSeconds={comment.seconds_ago}/>
                    <br/>
                    {comment.comment}
                    </CardContent>
                    </Card>
                );
            })}
            {donationsProjects.comments.length > 0 ? 
                <Card className="donations_cards mb-20 grey8 fs-14">
                    <CardContent>                        
                        Els comentaris publicats en aquesta pàgina web són responsabilitat exclusiva dels seus autors i no representen necessàriament l'opinió de la Federació d'Ateneus de Catalunya. Ens reservem el dret de retirar aquells comentaris que considerem inadequats o que incompleixin les nostres polítiques d'ús. 
                    </CardContent>
                </Card>
            : null }
            </>
            : null }
        </Grid>
        
      </Grid>
      
      </Box>
      
      { hash_preview ?
      <>
      <Box sx={{ width: '95%', bgcolor: '#FFD3D3', paddingLeft: '2%', paddingRight: '2%',textAlign: 'center', borderRadius: '10px', padding : '10px'}} className="add_member_box mt-10" >
        <span>{t('preview_index')}</span> 
      </Box>
      <br />
      <Box sx={{ width: '95%', bgcolor: 'background.paper', paddingLeft: '1%', paddingRight: '1%' }} className="add_member_box pt-0 mt-10" >
      <Grid container spacing={2}>
      <Grid key={donationsProjects.id} item xs="12" sm="6" lg="4" textAlign="left" className="classrooms_list_item_container">  
              <Card className="donations_cards">                            
                            
                { donationsProjects.images && donationsProjects.images.length > 0 ? 
                  <ImageViewer docs_id={donationsProjects.images[0]} is_public={true} title={donationsProjects.title} is_div={true} className="public-events-img" />
                  : 
                  null
                }                
                <ProjectResume list={true} item={donationsProjects} />                
              </Card>
            </Grid> 
      </Grid> 
      </Box>
      <br /><br />
      </>
      : null }
      
      <FooterFAC associations_id={donationsProjects.associations_id} />  
        
    </div>
  );
};
   
   
export const PublicDonationConfirm = props => {
    
    const {t} = useTranslation();  
    const [info, setInfo] = useState();    
    const [error, setError] = useState();
    const [formSent, setFormSent] = useState();
    
    const location = useLocation();
    
    const { Ds_SignatureVersion: ds_SignatureVersion } = parse(location.search);
    const { Ds_MerchantParameters: ds_MerchantParameters } = parse(location.search);
    const { Ds_Signature: ds_Signature } = parse(location.search);
    const { sha1hash: sha1hash } = parse(location.search);
            
    useEffect(() => {
                
        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };
          
          if (ds_MerchantParameters && ds_Signature && ds_SignatureVersion)
          {
              axios.post(apiUrl + '/donations_users/public/notify/confirm', { 
                          Ds_SignatureVersion : ds_SignatureVersion,
                          Ds_MerchantParameters : ds_MerchantParameters,
                          Ds_Signature: ds_Signature  } , config )
                .then(function (response) {
                    setInfo(response.data);            
                })
                .catch(function (error) {
                    let message = "S'ha produit un error";
                    if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                    return message;
              });
          }
          else if (sha1hash)
          {
              axios.post(apiUrl + '/donations_users/public/notify/show?sha1hash='+sha1hash, { } , config )
                .then(function (response) {                  
                    setInfo(response.data);            
                })
                .catch(function (error) {
                    let message = "S'ha produit un error";
                    if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                    return message;
              });
          }
          
    }, [] );
    
    useEffect(() => {
        
    });
    
    if (!info) return null;
    
    if (info.payment_method == 1 && !info.status == '1') return 'Estat incorrecte';
    
    return (
            <div className="donations_projects">
    <HeaderFAC associations_id={info.associations_id} />
    <Box sx={{ width: '80%', bgcolor: 'background.paper', paddingLeft: '10%', paddingRight: '10%' }} className="add_member_box pt-0 mt-30 mb-30" >
    
    <br /><br />
    
    <span className="mt-30 mb-30">
    <div dangerouslySetInnerHTML={{ __html: info.message }} />
    </span>
    
    <span className="mt-30 mb-30">    
    </span>
    
    <br /><br/><a href={'/' + info.friendly_url} className="classrooms_list_item_link"><Button size="small" className="donations-buttons-2">{t('close')} </Button></a>
    </Box>
    <FooterFAC associations_id={info.associations_id} />
    </div>);
}

export const getOrderId = (id) => {    
    const numeroFormatat = String(id).padStart(8, '0');
    return "DO-" + numeroFormatat;
}

export const toastError = (message) => {
    toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
}

export const PublicDonationError = props => {
    
    const {t} = useTranslation();  
    const [info, setInfo] = useState();
    const [error, setError] = useState();
    const [formSent, setFormSent] = useState();
    
    const location = useLocation();
    
    const { Ds_SignatureVersion: ds_SignatureVersion } = parse(location.search);
    const { Ds_MerchantParameters: ds_MerchantParameters } = parse(location.search);
    const { Ds_Signature: ds_Signature } = parse(location.search);
    const { sha1hash: sha1hash } = parse(location.search);
      
    useEffect(() => {
        
        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };
          
        if (ds_MerchantParameters && ds_Signature && ds_SignatureVersion)
        {
            axios.post(apiUrl + '/donations_users/public/notify/error', { 
                          Ds_SignatureVersion : ds_SignatureVersion,
                          Ds_MerchantParameters : ds_MerchantParameters,
                          Ds_Signature: ds_Signature  } , config )
              .then(function (response) {
                setInfo(response.data);            
              })
              .catch(function (error) {

                let message = "S'ha produit un error";
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                return message;

              });
          }
          else if (sha1hash)
          {
              axios.post(apiUrl + '/donations_users/public/notify/show?sha1hash='+sha1hash, { } , config )
                .then(function (response) {
                    setInfo(response.data);            
                })
                .catch(function (error) {
                    let message = "S'ha produit un error";
                    if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                    return message;
              });
          }
                  
    }, [ ]);
    
    if (!info) return null;
    
    if (info.status == '1') return 'Estat incorrecte';
    
    return (<div className="donations_projects">
    <HeaderFAC associations_id={info.associations_id} />
    <Box sx={{ width: '80%', bgcolor: 'background.paper', paddingLeft: '10%', paddingRight: '10%' }} className="add_member_box pt-0 mt-30 mb-30" >
    <span className="red">Ops {info.first_name}! El pagament no s'ha completat!</span>
    {info.tpv_message ? <><br /><br/><span className="red">{info.tpv_status}: {info.tpv_message}</span></> : null }
    <br /><br/><a href={'/' + info.friendly_url + '?retry_payment=' + sha1hash } className="classrooms_list_item_link"><Button size="small" className="donations-buttons-2">{t('retry')} </Button></a>
    </Box>
    <FooterFAC associations_id={info.associations_id} />
    </div>);
    
}



   
export const PublicDonationPrivacy = props => {
    
    const {t} = useTranslation();  
    
    const navigate = useNavigate();  
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);    

    const { hash, pathname, search } = location;

    const { associations_id: associations_id_string } = parse(search);  

    const [info, setInfo] = useState();
    const [donationsProjects, setDonationsProjects] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  

    const { associations_id } = useParams();
    
    useEffect(() => {
        let url  = '';
        if (associations_id)    
            url = apiUrl + '/donations_projects/public/html?associations_id=' + associations_id;
        else
            url = apiUrl + '/donations_projects/public/html/fac';

        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };

        axios.get(url, config).then((response) => {      
          setInfo(response.data);      
        })
        .catch((error) => {      
        });
    },[] );
    
    if (!info) return null;
    
    /*
     * <h2>Informació relativa al tractament de les dades personals</h2>
            <br />
            <p><b>Identificaci&oacute; del responsable:</b> &ldquo;Federaci&oacute; d&rsquo;Ateneus de Catalunya&rdquo;, amb domicili a Carrer de la S&egrave;quia 9-11, 08003 de Barcelona, amb n&uacute;mero de NIF: G58212903, inscrita al Registre d&rsquo;Associacions n&uacute;mero 59 de la secci&oacute; 2&ordf; del registre de Barcelona. Correu electr&ograve;nic de contacte <a data-fr-linked="true" href="mailto:ateneus@ateneus.cat">ateneus@ateneus.cat</a>.</p>
<p><b>Finalitat del tractament:</b>
<br />Totes les dades facilitades seran tractades amb les seg&uuml;ents finalitats:
<br />
<ul>
<li>Donar resposta a les peticions realitzades per l&rsquo;interessat.</li>
<li>Donar assessorament i suport a les entitats federades.</li>
<li>Gesti&oacute;, administraci&oacute;, informaci&oacute;, prestaci&oacute; i millora de les activitats i esdeveniments que l&rsquo;usuari pugui realitzar.</li>
</ul>
</p>

<p><b>Legitimaci&oacute; del tractament:</b>
<br />Tot tractament realitzat sobre les seves dades personals ha estat consentit per vost&egrave; de manera pr&egrave;via i expressa. Amb car&agrave;cter previ se li informa de tots aquells extrems exigits per la Llei perqu&egrave; pugui prestar un consentiment informat.
<br />Podr&agrave; revocar aquest consentiment a qualsevol moment tal com es detalla m&eacute;s endavant en el present document. </p>

<p><b>Destinataris, cessions i transfer&egrave;ncies de dades:</b>
<br />Les seves dades no seran cedides tret que siguin estrictament indispensable pel compliment de les finalitats anteriorment dites o obligaci&oacute; legal.
<br />Les seves dades no seran transferides a pa&iuml;sos fora de la Uni&oacute; Europea si no fos indispensable per al compliment de les finalitats i vost&egrave; ens autoritzi de forma pr&egrave;via i expressa.
</p>

<p><b>Drets dels interessats:</b>
<br />Podr&agrave; exercir en qualsevol moment els drets que es detallen a continuaci&oacute;, mitjan&ccedil;ant correu postal a &ldquo;Federaci&oacute; d&rsquo;Ateneus de Catalunya&rdquo;, Carrer de la S&egrave;quia 9-11, 08003 de Barcelona; o mitjan&ccedil;ant correu electr&ograve;nic a <a data-fr-linked="true" href="mailto:ateneus@ateneus.cat">ateneus@ateneus.cat</a>. La sol&middot;licitud haur&agrave; de contenir el nom, cognoms i la documentaci&oacute; que acrediti la identitat de l&rsquo;interessat o del seu representant legal, aix&iacute; com document acreditatiu de la representaci&oacute;, petici&oacute; en la qual es concreti la sol&middot;licitud, domicili a l&rsquo;efecte de notificacions, data i signatura del sol&middot;licitant i documents acreditatius de la petici&oacute; que formula. Si la sol&middot;licitud no reuneix els requisits especificats es requerir&agrave; la seva esmena.
<br />
Els drets dels interessats s&oacute;n: d&rsquo;acc&eacute;s, de rectificaci&oacute;, limitaci&oacute;, portabilitat, oposici&oacute; i supressi&oacute;; i la seva definici&oacute; &eacute;s:</p>

<p><u>Dret d&rsquo;acc&eacute;s:</u>
<br />L&rsquo;interessat tindr&agrave; dret a obtenir del responsable del tractament confirmaci&oacute; de si s&rsquo;estan tractant o no dades personals que el concerneixen i, en tal cas, dret d&rsquo;acc&eacute;s a les dades personals i a la seg&uuml;ent informaci&oacute;:
<ul>
<li>1. a) les finalitats del tractament</li>
<li>2. b) les categories de dades personals que es tractin</li>
<li>3. c) els destinataris o les categories de destinataris als quals s&rsquo;han comunicat o seran comunicats les dades personals, en particular destinataris en tercers pa&iuml;sos o organitzacions internacionals</li>
<li>4. d) de ser possible, el termini previst de conservaci&oacute; de les dades personals o, de no ser possible, els criteris utilitzats per determinar aquest termini</li>
<li>5. i) l&rsquo;exist&egrave;ncia del dret a sol&middot;licitar del responsable la rectificaci&oacute; o supressi&oacute; de dades personals o la limitaci&oacute; del tractament de dades personals relatives a l&rsquo;interessat, o a oposar-se a aquest tractament</li>
<li>6. f) el dret a presentar una reclamaci&oacute; davant una autoritat de control</li>
<li>7. g) quan les dades personals no s&rsquo;hagin obtingut de l&rsquo;interessat, qualsevol informaci&oacute; disponible sobre el seu origen</li>
<li>8. h) l&rsquo;exist&egrave;ncia de decisions automatitzades, inclosa l&rsquo;elaboraci&oacute; de perfils, al fet que es refereix l&rsquo;article 22, apartats 1 i 4, i, almenys en aquests casos, informaci&oacute; significativa sobre la l&ograve;gica aplicada, aix&iacute; com la import&agrave;ncia i les conseq&uuml;&egrave;ncies previstes d&rsquo;aquest tractament per a l&rsquo;interessat.</li>
</ul>
</p>

<p>Quan es transfereixin dades personals a un tercer pa&iacute;s o a una organitzaci&oacute; internacional, l&rsquo;interessat tindr&agrave; dret a ser informat de les garanties adequades en virtut de l&rsquo;article 46 relatives a la transfer&egrave;ncia. El responsable del tractament facilitar&agrave; una c&ograve;pia de les dades personals objecte de tractament. El responsable podr&agrave; percebre per qualsevol altra c&ograve;pia sol&middot;licitada per l&rsquo;interessat un c&agrave;non raonable basat en els costos administratius. Quan l&rsquo;interessat presenti la sol&middot;licitud per mitjans electr&ograve;nics, i tret que aquest sol&middot;liciti que es faciliti d&rsquo;una altra manera, la informaci&oacute; es facilitar&agrave; en un format electr&ograve;nic d&rsquo;&uacute;s com&uacute;.</p>

<p><u>Dret de rectificaci&oacute;:</u>
<br />L&rsquo;interessat tindr&agrave; dret a obtenir sense dilaci&oacute; indeguda del responsable del tractament la rectificaci&oacute; de les dades personals inexactes que el concerneixin.
<br />Tenint en compte les finalitats del tractament, l&rsquo;interessat tindr&agrave; dret a que es completin les dades personals que siguin incomplets, fins i tot mitjan&ccedil;ant una declaraci&oacute; addicional.</p>

<p><u>Dret de supressi&oacute;:</u>
<br />L&rsquo;interessat tindr&agrave; dret a obtenir sense dilaci&oacute; indeguda del responsable del tractament la supressi&oacute; de les dades personals que el concerneixin, el qual estar&agrave; obligat a suprimir sense dilaci&oacute; indeguda les dades personals quan concorri alguna de les circumst&agrave;ncies seg&uuml;ents:
<ul>
<li>1. a) les dades personals ja no siguin necessaris en relaci&oacute; amb les 
finalitats pels quals van ser recollits o tractats d&rsquo;una altra manera </li>
<li>2. b) l&rsquo;interessat retiri el consentiment en qu&egrave; es basa el tractament de 
conformitat amb l&rsquo;article 6, apartat 1, lletra a), o l&rsquo;article 9, apartat 2, 
lletra a), i aquest no es basa en un altre fonament jur&iacute;dic </li>
<li>3. c) l&rsquo;interessat s&rsquo;oposi al tractament conformement a l&rsquo;article 21, apartat 1, 
i no prevalguin altres motius leg&iacute;tims per al tractament, o l&rsquo;interessat 
s&rsquo;oposi al tractament conformement a l&rsquo;article 21, apartat 2 </li>
<li>4. d) les dades personals hagin estat tractats il&middot;l&iacute;citament </li>
<li>5. i) les dades personals hagin de suprimir-se pel compliment d&rsquo;una 
obligaci&oacute; legal establerta en el Dret de la Uni&oacute; o dels Estats membres 
que s&rsquo;apliqui al responsable del tractament </li>
<li>6. f) les dades personals s&rsquo;hagin obtingut amb relaci&oacute; a l&rsquo;oferta de serveis 
de la societat de la informaci&oacute; esmentats en l&rsquo;article 8, apartat 1</li> 
</ul>
</p>

<p>Quan el responsable hagi fet p&uacute;bliques les dades personals de les quals l&rsquo;interessat ha exercit el seu dret de supressi&oacute;, el responsable haur&agrave; d&rsquo;adoptar, atenent la tecnologia disponible i el cost de la seva aplicaci&oacute;, les mesures raonables per comunicar als tercers que estiguin tractant aquesta informaci&oacute; de la sol&middot;licitud de supressi&oacute; de les dades, aix&iacute; com de qualsevol enlla&ccedil; a aquestes dades o a qualsevol c&ograve;pia o r&egrave;plica d&rsquo;aquestes.
<br />Aquest dret es veur&agrave; limitat per altres drets tals com el dret a la llibertat d&rsquo;expressi&oacute; i informaci&oacute;, pel compliment d&rsquo;alguna obligaci&oacute; legal, o quan existeixin raons d&rsquo;inter&egrave;s p&uacute;blic.</p>

<p><u>Dret a la limitaci&oacute; del tractament:</u>
<br />L&rsquo;interessat tindr&agrave; dret a obtenir del responsable del tractament la limitaci&oacute; del tractament de les dades quan es compleixi alguna de les condicions seg&uuml;ents:
<ul>
<li>1. a) l&rsquo;interessat impugni l&rsquo;exactitud de les dades personals, durant un termini que permeti al responsable verificar l&rsquo;exactitud de les mateixes</li>
<li>2. b) el tractament sigui il&middot;l&iacute;cit i l&rsquo;interessat s&rsquo;oposi a la supressi&oacute; de les dades personals i sol&middot;liciti en el seu lloc la limitaci&oacute; del seu &uacute;s</li>
<li>3. c) el responsable ja no necessiti les dades personals per les finalitats del tractament, per&ograve; l&rsquo;interessat els necessiti per la formulaci&oacute;, l&rsquo;exercici o la defensa de reclamacions</li>
<li>4. d) l&rsquo;interessat s&rsquo;hagi oposat al tractament en virtut de l&rsquo;article 21, apartat 1, mentre es verifica si els motius leg&iacute;tims del responsable prevalen sobre els de l&rsquo;interessat</li>
</ul>
</p>

<p>Quan el tractament de dades personals s&rsquo;hagi limitat en virtut de l&rsquo;apartat 1, aquestes dades nom&eacute;s podran ser objecte de tractament, amb excepci&oacute; de la seva conservaci&oacute;, amb el consentiment de l&rsquo;interessat o per a la formulaci&oacute;, l&rsquo;exercici o la defensa de reclamacions, o amb la intenci&oacute; de la protecci&oacute; dels drets d&rsquo;una altra persona f&iacute;sica o jur&iacute;dica o per raons d&rsquo;inter&egrave;s p&uacute;blic important de la Uni&oacute; o d&rsquo;un determinat Estat membre.
<br />Tot interessat que hagi obtingut la limitaci&oacute; del tractament conformement a l&rsquo;apartat 1 ser&agrave; informat pel responsable abans de l&rsquo;aixecament d&rsquo;aquesta limitaci&oacute;.
</p>

<p><u>Dret d&rsquo;oposici&oacute;:</u>
<br/>L&rsquo;interessat tindr&agrave; dret a oposar-se a qualsevol moment, per motius relacionats amb la seva situaci&oacute; particular, al fet que dades personals que el concerneixin siguin objecte d&rsquo;un tractament basat en el qual es disposa en l&rsquo;article 6, apartat 1, lletres i) o f), inclosa l&rsquo;elaboraci&oacute; de perfils sobre la base d&rsquo;aquestes disposicions.
<br />El responsable del tractament deixar&agrave; de tractar les dades personals, tret que acrediti motius leg&iacute;tims imperiosos per al tractament que prevalguin sobre els interessos, els drets i les llibertats de l&rsquo;interessat, o per la formulaci&oacute;, l&rsquo;exercici o la defensa de reclamacions.
<br />Quan el tractament de dades personals tingui per objecte el m&agrave;rqueting directe, l&rsquo;interessat tindr&agrave; dret a oposar-se a tot moment al tractament de les dades personals que el concerneixin, inclosa l&rsquo;elaboraci&oacute; de perfils en la mesura en qu&egrave; estigui relacionada amb el citat m&agrave;rqueting.
<br />Quan l&rsquo;interessat s&rsquo;oposi al tractament amb finalitats de m&agrave;rqueting directe, les dades personals deixaran de ser tractades per a aquestes finalitats.
<br />Quan les dades personals es tractin amb finalitats de recerca cient&iacute;fica o hist&ograve;rica o finalitats estad&iacute;stiques de conformitat amb l&rsquo;article 89, apartat 1, l&rsquo;interessat tindr&agrave; dret, per motius relacionats amb la seva situaci&oacute; particular, a oposar-se al tractament de dades personals que el concerneixin, tret que sigui necessari pel compliment d&rsquo;una missi&oacute; realitzada per raons d&rsquo;inter&egrave;s p&uacute;blic.
</p>

<p><u>Dret de portabilitat de dades:</u>
<br />L&rsquo;interessat tindr&agrave; dret a rebre les dades personals que li afectin, que hagi facilitat a un responsable del tractament, en un format estructurat, d&rsquo;&uacute;s com&uacute; i lectura mec&agrave;nica, i a transmetre&rsquo;ls a un altre responsable del tractament sense que ho impedeixi el responsable al qual li hagu&eacute;s facilitat, quan:
<ul>
<li>1. a) el tractament estigui basat en el consentiment conformement a l&rsquo;article 6, apartat 1, lletra a), o l&rsquo;article 9, apartat 2, lletra a), o en un contracte conformement a l&rsquo;article 6, apartat 1, lletra b), i </li>
<li>2. b) el tractament s&rsquo;efectu&iuml; per mitjans automatitzats.</li>
</ul>
</p>

<p>2.A l&rsquo;exercir el seu dret a la portabilitat de les dades d&rsquo;acord amb l&rsquo;apartat 1, l&rsquo;interessat tindr&agrave; dret a que les dades personals es transmetin directament de responsable a responsable quan sigui t&egrave;cnicament possible.
<br />L&rsquo;exercici del dret esmentat a l&rsquo;apartat 1 del present article s&rsquo;entendr&agrave; sense perjudici de l&rsquo;article 17. Tal dret no s&rsquo;aplicar&agrave; al tractament que sigui necessari per al compliment d&rsquo;una missi&oacute; realitzada en inter&egrave;s p&uacute;blic o en l&rsquo;exercici de poders p&uacute;blics conferits al responsable del tractament.
<br />El responsable del tractament comunicar&agrave; qualsevol rectificaci&oacute; o supressi&oacute; de dades personals o limitaci&oacute; del tractament efectuada conformement a l&rsquo;article 16, a l&rsquo;article 17, apartat 1, i a l&rsquo;article 18 a cadascun dels destinataris als quals s&rsquo;hagin comunicat les dades personals, tret que sigui impossible o exigeixi un esfor&ccedil; desproporcionat. El responsable informar&agrave; l&rsquo;interessat sobre aquests destinataris, si aquest aix&iacute; ho sol&middot;licita.
</p>

<p><b>Revocaci&oacute; del consentiment:</b> L&rsquo;interessat, que en el seu moment hagu&eacute;s atorgat el consentiment per tractar les seves dades personals, podr&agrave; igualment retirar-ho amb igual facilitat. La retirada del consentiment no comportar&agrave; la il&middot;licitud del tractament efectuat amb anterioritat.
<br />L&rsquo;interessat tindr&agrave; dret a presentar una reclamaci&oacute; davant l&rsquo;Autoritat de Control competent.
<br />Quan les dades personals vagin a ser tractades amb posterioritat amb finalitats diferents, el responsable informar&agrave; l&rsquo;interessat de tal extrem.</p>

<p><b>Mesures de seguretat:</b> El Responsable del Tractament manifesta que ha adoptat les mesures d&rsquo;&iacute;ndole t&egrave;cnica i organitzativa necess&agrave;ries per garantir la seguretat de les dades i evitar la seva alteraci&oacute;, p&egrave;rdua, tractament o acc&eacute;s no autoritzat, tenint en compte l&rsquo;estat de la tecnologia, la naturalesa de les dades emmagatzemades i els riscos als quals es troben exposades, ja provinguin de l&rsquo;acci&oacute; humana o del mitj&agrave; f&iacute;sic o natural.</p>
<p>El Web <a data-fr-linked="true" href="//www.ateneus.cat">www.ateneus.cat</a> cont&eacute; enlla&ccedil;os a altres p&agrave;gines Web que puguin resultar d&rsquo;inter&egrave;s per als usuaris. &ldquo;Federaci&oacute; d&rsquo;Ateneus de Catalunya&rdquo; no assumeix cap responsabilitat sobre aquests enlla&ccedil;os, sense que es pugui donar cap garantia en el compliment de pol&iacute;tiques de privacitat adequades, per la qual cosa l&rsquo;usuari accedeix al contingut de les referides p&agrave;gines Web en les condicions d&rsquo;&uacute;s que es fixin en les mateixes i sota la seva exclusiva responsabilitat.</p>
<p>Si t&eacute; algun dubte, q&uuml;esti&oacute; o recomanaci&oacute; sobre la nostra Pol&iacute;tica de Privacitat, poden dirigir-se a trav&eacute;s de correu electr&ograve;nic a la seg&uuml;ent adre&ccedil;a: <a data-fr-linked="true" href="mailto:ateneus@ateneus.cat">ateneus@ateneus.cat</a>.</p>
<p>&ldquo;Federaci&oacute; d&rsquo;Ateneus de Catalunya&rdquo;
<br />Tots els drets reservats</p>
     */
    
    return (
            <div className="donations_projects">
            <HeaderFAC associations_id={associations_id} />
            <Box sx={{ width: '80%', bgcolor: 'background.paper', paddingLeft: '10%', paddingRight: '10%' }} className="add_member_box pt-0 mt-30 mb-30" >            

                <div dangerouslySetInnerHTML={{ __html: info.donations_own_privacy }} />
        
            </Box>
            <FooterFAC associations_id={associations_id} />
            </div>);
}

export const PublicDonationConditions = props => {
    
    const {t} = useTranslation();  
    
    const navigate = useNavigate();  
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);    

    const { hash, pathname, search } = location;

    const { associations_id: associations_id_string } = parse(search);  

    const [info, setInfo] = useState();
    const [donationsProjects, setDonationsProjects] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  

    const { associations_id } = useParams();
    
    useEffect(() => {
        let url  = '';
        if (associations_id)    
            url = apiUrl + '/donations_projects/public/html?associations_id=' + associations_id;
        else
            url = apiUrl + '/donations_projects/public/html/fac';

        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };

        axios.get(url, config).then((response) => {      
          setInfo(response.data);      
        })
        .catch((error) => {      
        });
    },[] );
    
    if (!info) return null;
    
    /*
     * <h2>Termes i condicions dels donatius</h2>
            <br />
            <p>
                1- La Federació d’Ateneus de Catalunya (FAC) està declarada com a Entitat
                d’utilitat pública segons la <a href="https://dogc.gencat.cat/ca/document-del-dogc/?documentId=632777" target="_blank">resolució JUS/675/2013</a></p>
<p>2- Les donacions a les Entitats d’Utilitat Pública són a fons perdut.</p>
<p>3- La FAC cedirà les dades de les persones físiques o jurídiques a l’entitat
membre de la FAC, responsable del projecte al que va destinada la donació.</p>
<p>4- Les persones físiques o jurídiques que hagin realitzat donacions, i que hagin
marcat al formulari la casella sol·licito el certificat de donació, rebran, de part de
la Federació d’Ateneus de Catalunya, el certificat de donació al gener del
següent any al que han fet la donació.
            </p>
            <br />
            <br />
     */
    
    return (
            <div className="donations_projects">
            <HeaderFAC associations_id={associations_id} />
            <Box sx={{ width: '80%', bgcolor: 'background.paper', paddingLeft: '10%', paddingRight: '10%' }} className="add_member_box pt-0 mt-30 mb-30" >
            <div dangerouslySetInnerHTML={{ __html: info.donations_own_terms }} />
            </Box>
            <FooterFAC associations_id={associations_id} />
            </div>);
}

export const PublicDonationFiscal = (props) => {
    
    const {t} = useTranslation();  
    
    const navigate = useNavigate();  
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);    

    const { hash, pathname, search } = location;

    const { associations_id: associations_id_string } = parse(search);  

    const [info, setInfo] = useState();
    const [donationsProjects, setDonationsProjects] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  

    const { associations_id } = useParams();
    
    useEffect(() => {
        let url  = '';
        if (associations_id)    
            url = apiUrl + '/donations_projects/public/html?associations_id=' + associations_id;
        else
            url = apiUrl + '/donations_projects/public/html/fac';

        const config = {
            headers: {
              'Content-Type': 'application/json', 
              'Accept-Language': 'ca' 
            }
          };

        axios.get(url, config).then((response) => {      
          setInfo(response.data);      
        })
        .catch((error) => {      
        });
    },[] );
    
    if (!info) return null;
    
    /*
     * <br />
            <p>Les donacions a Entitats d&#39;Utilitat Pública són a fons perdut i compten amb la següent <b>exempció fiscal</b>: </p>
            <br />
            <ul>
            <li>Si la donació la fan <b>persones jurídiques</b>, la desgravació és del 40% i del 50% si donen la mateixa quantitat o superior durant 3 anys.</li>
            <br /><li>Si la donació la fan <b>persones físiques</b>:
                <br /><br />
                <ul>
                <li>Desgravació:</li>
                <li>Desgravació del 80% dels primers 250 €</li>
                <li>Desgravació del 15% de tot l’import (català)</li>
                <li>Per tant, dels primers 250 € desgravació del 95%</li>
                <li>A partir de 250€ desgravació del 40% (que + 15% foment del català) = 55%</li>
                </ul>
                <br />
                <ul>
                <li>Recurrència de donatius durant 3 anys (donatius iguals o superiors cada any):
                <br />
                <ul>
                <li>Desgravació del 80% dels primers 250 €</li>
                <li>Desgravació del 15% de tot l’import (català)</li>
                <li>Per tant, dels primers 250 € desgravació del 95%</li>
                <li>A partir de 250€ desgravació del 45% (que + 15% foment del català) = 60%</li>
                </ul>
                </li>
                </ul>
            </li>
            </ul>
            <br /><br />
     */    
                            
    return (
    <div className="donations_projects">
    <HeaderFAC associations_id={associations_id} />
      
    <Box sx={{ width: '95%', bgcolor: 'background.paper', paddingLeft: '1%', paddingRight: '1%' }} className="add_member_box pt-0 mt-10" >
      
      <h2>Beneficis fiscals</h2>
      <div dangerouslySetInnerHTML={{ __html: info.donations_own_fiscal }} />
                  
    </Box>
        
    <FooterFAC associations_id={associations_id} />
        
    </div>
  );
            
}
