import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import catalanMessages from './catalanMessages.json'; // Importa tus mensajes en catalán

// Configura i18next
i18n
  .use(initReactI18next) // inicializa i18next para react
  .init({
    resources: {
      ca: {
        translation: {
            not_actived: 'L\'entitat no té projectes de donatius actius',
            for: 'Per',
            live: 'En marxa!',
            early: 'Començarà aviat',
            finished: 'Finalitzat!',
            count_donations: 'Finançadors',
            goal: 'Objectiu',
            view_all_projects: 'Tots els projectes',
            remain: 'Queden',
            remain_1: 'Queda',
            win: 'Aconseguits',
            more_info: '+ Info',
            contribute: 'Aportar',
            contribute_to: 'Aportar a',
            association_not_found : 'L\'entitat no s\'ha trobat o hi ha hagut un error',
            not_found : 'El projecte no s\'ha trobat o hi ha hagut un error',
            view_web: 'Agenda web',
            go_back: 'Tornar enrere',
            filter_date_after: 'Comença després de',
            filter_date_before: 'Comença abans de',
            filter_organizer_all: 'Totes les entitats',
            comments: 'Comentaris de finançadors',
            ago: 'fa',
            close: 'D\'acord',
            return: 'Tornar',
            retry: 'Torna-ho a provar',
            preview: 'Previsualització de la fitxa del projecte',
            preview_index: 'Previsualització del projecte en el llistat de projectes',
            minute: 'minut',
            minutes: 'minuts',
            hour: 'hora',
            hours: 'hores',
            day: 'dia',
            days: 'dies',
            seconds: 'segons',
            second: 'segon',
            search: 'Cercar projectes',   
            cancel: 'Cancel·lar',
            pay: 'Pagar',
            pay_card: 'Targeta',
            pay_transfer: 'Transferència',
            amount: 'Import (€)',
            first_name: 'Nom',
            last_name_1: 'Cognom 1',
            last_name_2: 'Cognom 2',
            email: 'Correu-e',
            email_repeat: 'Repeteix el correu-e',
            nif: 'DNI/NIF',
            address: 'Adreça',
            zip: 'Codi postal',
            city: 'Població',
            country: 'País',
            phone: 'Telèfon',
            user_type: 'Tipus',
            user_type_0: 'Persona',
            user_type_1: 'Empresa',
            comment: 'Comentari públic per a la pàgina del projecte (Opcional)',
            lopd: 'Accepto el tractament de les meves dades',
            conditions: 'Accepto les condicions sobre la realització del donatiu en aquest projecte',
            payment_method: 'Forma de pagament',
            want_certificate: 'Sol·licito el certificat de donació',
        }
      }
    },
    lng: 'ca', // Establece el idioma predeterminado
    fallbackLng: 'ca', // Establece el idioma de reserva
    interpolation: {
      escapeValue: false // No escapar las cadenas traducidas
    }
  });


var catalanMessages = {
  "greeting": "Hola!",
  "welcome": "Benvingut a la nostra aplicació.",
  "logout": "Tanca la sessió"
};

export default i18n;
