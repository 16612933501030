export const YoutubeVideo = (props) => {
    
    let url_embed = '';
    if (props.url && (props.url.includes('youtube.com') || props.url.includes('youtu.be') ))
    {
        if (props.url.includes('embed')) 
        {
            url_embed = props.url;
        }
        else
        {
            url_embed = 'https://www.youtube.com/embed/' + getParam(props.url,'v');
        }
                    
        return (<div class="donations-video">
        <iframe src={url_embed} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>);
    }
    else return null;
        
}

function getParam(url, nomParametre) {
  // Crea una nova URL amb la URL proporcionada
  const urlObject = new URL(url);
  
  // Obtenim els paràmetres de la URL
  const searchParams = urlObject.searchParams;
  
  // Llegeix el valor del paràmetre especificat
  const valorParametre = searchParams.get(nomParametre);
  
  // Retorna el valor del paràmetre
  return valorParametre;
}


export default YoutubeVideo;